import Header from '../../shared/header/Header.vue'
import Footer from '../../shared/footer/Footer.vue'
import Truncate from 'vue-truncate-collapsed'

export default {
  name: 'Inventory',
  data: function () {
    return {
      isZoneTypeSelectActive: false,
      isSiteSelectActive: false,
      filters: {
        zoneType: undefined,
        site: undefined,
        language: 'en'
      },
      textFilter: ''
    }
  },
  components: {
    Header,
    Footer,
    Truncate
  },
  methods: {
    toggleSiteSelect: function () {
      this.isZoneTypeSelectActive = false
      this.isSiteSelectActive = !this.isSiteSelectActive
    },
    toggleZoneTypeSelect: function () {
      this.isSiteSelectActive = false
      this.isZoneTypeSelectActive = !this.isZoneTypeSelectActive
    },
    setSiteSelected: function (site) {
      this.removeFilters()
      this.filters.site = site
      this.$router.push({ path: '/inventory', query: { ...this.filters, search: this.textFilter } })
      this.retrieveZones()
    },
    setZoneTypeSelected: function (zoneType) {
      this.removeFilters()
      this.filters.zoneType = zoneType
      this.$router.push({ path: '/inventory', query: { ...this.filters, search: this.textFilter } })
      this.retrieveZones()
    },
    setLanguageSelected: function (language) {
      this.removeFilters()
      this.filters.language = language
      this.$router.push({ path: '/inventory', query: { ...this.filters, search: this.textFilter } })
      this.retrieveZones()
      this.retrieveZoneTypes()
    },
    removeFilters: function () {
      this.filters.site = undefined
      this.filters.zoneType = undefined
      this.isSiteSelectActive = false
      this.isZoneTypeSelectActive = false
      this.textFilter = ''
    },
    refreshFilters: function () {
      this.removeFilters()
      this.retrieveZones()
    },
    retrieveZones: function () {
      this.$store.dispatch('getZones', this.filters)
    },
    retrieveZoneTypes: function () {
      this.$store.dispatch('getZoneTypes', { language: this.filters.language })
    },
    textFilterChanged: function () {
      this.$router.push({ path: '/inventory', query: { ...this.filters, search: this.textFilter } })
    }
  },
  created () {
    const url = new URL(window.location)
    this.filters.language = url.searchParams.get('language') || 'en'
    this.filters.site = url.searchParams.get('site') || undefined
    this.filters.zoneType = url.searchParams.get('zoneType') || undefined
    this.textFilter = url.searchParams.get('search') || ''

    this.retrieveZones()
    this.retrieveZoneTypes()
    this.$store.dispatch('getSites')
    this.$store.dispatch('getLanguages')
  },
  computed: {
    zones () {
      return this.$store.state.inventory.zones.filter((zone) => {
        const lowerCaseTextFilter = this.textFilter.toLowerCase()
        const protocolPattern = /^((http|https):\/\/)/
        if (zone.screenshot_link === 'N/A') {
          zone.screenshot_link = ''
        } else if (!protocolPattern.test(zone.screenshot_link)) {
          zone.screenshot_link = 'https://' + zone.screenshot_link
        }
        return zone.zone.toLowerCase().includes(lowerCaseTextFilter) ||
          zone.size.toLowerCase().includes(lowerCaseTextFilter) ||
          zone.daily_average_volume.toLowerCase().includes(lowerCaseTextFilter) ||
          zone.rate.toLowerCase().includes(lowerCaseTextFilter) ||
          zone.dating_on_dating_rate.toLowerCase().includes(lowerCaseTextFilter) ||
          zone.rate_type.toLowerCase().includes(lowerCaseTextFilter) ||
          zone.daily_average_volume.toLowerCase().includes(lowerCaseTextFilter) ||
          zone.restrictions.toLowerCase().includes(lowerCaseTextFilter) ||
          zone.site.name.toLowerCase().includes(lowerCaseTextFilter) ||
          zone.zone_type.name.toLowerCase().includes(lowerCaseTextFilter)
      })
    },
    lastUpdate () {
      const d = this.$store.getters.getLastUpdate()

      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
      const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(d)
      const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
      return month + ' ' + day + ', ' + year
    },
    sites () {
      return this.$store.state.inventory.sites
    },
    languages () {
      return this.$store.state.inventory.languages
    },
    zoneTypes () {
      return this.$store.state.inventory.zoneTypes
    }
  }
}
